export default {
  en() {
    return {
      accommodation: 'Accommodation',
      add: 'ADD',
      addToCart: 'ADD TO CART',
      addToBasket: 'Add To Basket',
      chooseYourPackage: 'Choose Your Package & Quantity',
      continue: 'Continue',
      discount: 'Discount',
      dueDateTextTwo: 'Payments will be split equally and due at signing & on {date}',
      dueDateTextMultiple: 'Payments will be split equally and due at signing, on {datesList}',
      error: 'There was an error with your submission',
      noAccommodations: 'No Accommodations',
      numberOfPeople: '# of People',
      package: 'Package',
      packageInCart:'{yourPackage} is in your cart. To edit or checkout,',
      packagePricing: 'Package Pricing',
      packageQty: 'Package Qty',
      paymentCount: 'or {count} payments of',
      chooseYourQuantity: 'Choose Your Quantity',
      readyToCall: '<span>Ready to Book? Call {phoneLink}</span> or complete the form for pricing and package options.',
      roomQty: 'Room Qty',
      roomType: 'Room Type',
      soldOut: 'Sold Out',
      subtotal: 'Subtotal:',
      takenInCart: '(Taken in Cart)',
      yourTicket: 'Your Ticket',
      viewCart: 'view your cart»'
    }
  },
  ar() {
    return {
      accommodation: 'مكان الإقامة',
      add: 'أضف',
      addToCart: 'أضِفْ إلى سلّة/عربة التسوّق',
      addToBasket: 'Add To Basket',
      chooseYourPackage: 'اقبل \'، // https://qe360.quintevents.com/admin/phrases/20122',
      continue: 'واصلْ',
      discount: 'تخفيض',
      dueDateTextTwo: 'سيتمّ تقسيم الدفعات المُستحقّة بالتساوي عند التوقيع وفي {date}',
      dueDateTextMultiple: 'سيتمّ تقسيط الدفوعات المُستحقّة بالتساوي عند التوقيع، في {datesList}',
      error: 'كان هناك خطأ في تقديم طلبك',
      noAccommodations: 'لا توجد أماكن إقامة',
      numberOfPeople: '# من الناس',
      package: 'باقة',
      packageInCart: '.لقد أُضيفت {yourPackage} إلى سلّة تسوّقك. لإدخال التغييرات وتسجيل الخروج',
      packagePricing: 'سعر الباقة',
      packageQty: 'كميّة الباقات',
      paymentCount: 'أو {count} دفوعات',
      chooseYourQuantity: 'حدّد كمّيّتك',
      readyToCall: '<span>هل أنت مُستعدّ للحجز؟ اتّصل بـ {phoneLink}</span> أو املأ الاستمارة لاختيار الباقات والاطّلاع على أسعارها',
      roomQty: 'عدد الغرف',
      roomType: 'نوع الغرفة',
      soldOut: 'نفاذ الكميّة/تمّ بيع كلّ المنتجات',
      subtotal: ':المجموع الفرعيّ',
      takenInCart: '(تمّ إضافته إلى سلّة التسوّق)',
      yourTicket: 'تذكرتُك',
      viewCart: 'اطّلع على مُحتوى سلّة تسوّقك',
    }
  },
  de() {
    return {
      accommodation: 'Unterkunft',
      add: 'HINZUFÜGEN',
      addToCart: 'ZUM WARENKORB HINZUFÜGEN',
      addToBasket: 'In den Warenkorb',
      chooseYourPackage: 'Wählen Sie Ihr Paket & die Anzahl',
      continue: 'Fortfahren',
      discount: 'Ermäßigung',
      dueDateTextTwo: 'Die Zahlungen werden gleichmäßig aufgeteilt und sind bei Unterzeichnung & am {date} fällig.',
      dueDateTextMultiple: 'Die Zahlungen werden gleichmäßig aufgeteilt und sind bei Vertragsabschluss fällig, am {datesList}',
      error: 'Bei Ihrer Übermittlung ist ein Fehler aufgetreten',
      noAccommodations: 'Keine Unterkünfte',
      numberOfPeople: '# Personen',
      package: 'Paket',
      packageInCart: '{yourPackage} befindet sich in Ihrem Warenkorb. Um es zu bearbeiten oder zur Kasse zu gehen',
      packagePricing: 'Paketpreise',
      packageQty: 'Anzahl Pakete',
      paymentCount: 'oder {count} Zahlungen von',
      chooseYourQuantity: 'Anzahl auswählen',
      readyToCall: '<span>Bereit, zu buchen? Rufen Sie uns unter {phoneLink}</span> an oder füllen Sie das Formular aus, um mehr über Preise und Paketoptionen zu erfahren.',
      roomQty: 'Anzahl Zimmer',
      roomType: 'Zimmertyp',
      soldOut: 'Ausverkauft',
      subtotal: 'Zwischentotal:',
      takenInCart: '(In den Warenkorb gelegt)',
      yourTicket: 'Ihr Ticket',
      viewCart: 'Ihren Warenkorb anzeigen»',
    }
  },
  enGB() {
    return {
      accommodation: 'Accommodation',
      add: 'ADD',
      addToCart: 'ADD TO CART',
      addToBasket: 'Add To Basket',
      chooseYourPackage: 'Choose Your Package & Quantity',
      continue: 'Continue',
      discount: 'Discount',
      dueDateTextTwo: 'Payments will be split equally and due at signing & on {date}',
      dueDateTextMultiple: 'Payments will be split equally and due at signing, on {datesList}',
      error: 'There was an error with your submission',
      noAccommodations: 'No Accommodations',
      numberOfPeople: '# of People',
      package: 'Package',
      packageInCart: '{yourPackage} is in your cart. To edit or checkout,',
      packagePricing: 'Package Pricing',
      packageQty: 'Package Qty',
      paymentCount: 'or {count} payments of',
      chooseYourQuantity: 'Choose Your Quantity',
      readyToCall: '<span>Ready to Book? Call {phoneLink}</span> or complete the form for pricing and package options.',
      roomQty: 'Room Qty',
      roomType: 'Room Type',
      soldOut: 'Sold Out',
      subtotal: 'Subtotal:',
      takenInCart: '(Taken in Cart)',
      yourTicket: 'Your Ticket',
      viewCart: 'view your cart»',
    }
  },
  esMX() {
    return {
      accommodation: 'Alojamiento',
      add: 'Añadir',
      addToCart: 'Añadir al Carrito',
      addToBasket: 'Añadir a la Canasta',
      chooseYourPackage: 'Elija su paquete y cantidad',
      continue: 'Continuar',
      discount: 'Descuento',
      dueDateTextTwo: 'Los pagos serán divididos en dos partes iguales y son pagaderos al firmar en {date}',
      dueDateTextMultiple: 'Los pagos serán divididos de manera equitativa y serán pagaderos al momento de la firma en {datesList}',
      error: 'Hubo un error con el envío de su información',
      noAccommodations: 'Sin Alojamiento',
      numberOfPeople: '# de Personas',
      package: 'Paquete',
      packageInCart: '{yourPackage} está en su carrito. Para editarlo o proceder al pago,',
      packagePricing: 'Precio de Paquete',
      packageQty: 'Cantidad de Paquetes',
      paymentCount: 'o {count} pagos de',
      chooseYourQuantity: 'Elija Su Cantidad',
      readyToCall: '<span>¿Listo para reservar? Llame al {phoneLink}</span> o complete el formulario para conocer los precios y las opciones de los paquetes.',
      roomQty: 'Cantidad de Habitaciones',
      roomType: 'Tipo de Habitación',
      soldOut: 'Vendido',
      subtotal: 'Subtotal:',
      takenInCart: '(En el carrito)',
      yourTicket: 'Su boleto',
      viewCart: 'ver su carrito»',
    }
  },
  es() {
    return {
      accommodation: 'Alojamiento',
      add: 'AÑADIR',
      addToCart: 'AÑADIR AL CARRITO',
      addToBasket: 'Añadir a la Canasta',
      chooseYourPackage: 'Elige Tu Paquete y Cantidad',
      continue: 'Continuar',
      discount: 'Descuento',
      dueDateTextTwo: 'Los pagos se dividirán en partes iguales y serán abonados a la firma y el {date}',
      dueDateTextMultiple: 'Los pagos se dividirán en partes iguales y serán abonados a la firma, el {datesList}',
      error: 'Ha habido un error en tu envío',
      noAccommodations: 'Sin Alojamientos',
      numberOfPeople: '# de Personas',
      package: 'Paquete',
      packageInCart: '{yourPackage} está en tu carrito. Para editar o verificar,',
      packagePricing: 'Precio del Paquete',
      packageQty: 'Número de Paquetes',
      paymentCount: 'o {count} pagos de',
      chooseYourQuantity: 'Elige Tu Cantidad',
      readyToCall: '<span>¿Listo para Reservar? Llama al {phoneLink}</span> o completa el formulario para conocer los precios y las opciones de los paquetes.',
      roomQty: 'Número de Habitaciones',
      roomType: 'Tipo de Habitación',
      soldOut: 'Agotado',
      subtotal: 'Subtotal:',
      takenInCart: '(Cogido en el Carrito)',
      yourTicket: 'Tu Entrada',
      viewCart: 'ver tu carrito»',
    }
  },
  fr() {
    return {
      accommodation: 'Logement',
      add: 'AJOUTER',
      addToCart: 'AJOUTER AU PANIER',
      addToBasket: 'Ajouter Au Panier',
      chooseYourPackage: 'Choisissez votre forfait et sa quantité',
      continue: 'Continuer',
      discount: 'Remise',
      dueDateTextTwo: 'Le paiement sera divisé en versements égaux dus à la signature du contrat et à la date du {date}.',
      dueDateTextMultiple: 'Le paiement sera divisé en versements égaux dus à la signature du contrat, et aux dates suivantes : {datesList}',
      error: 'Une erreur est survenue lors votre inscription',
      noAccommodations: 'Pas d\'hébergement',
      numberOfPeople: 'Nombre de personnes',
      package: 'Forfait',
      packageInCart: '{yourPackage} est dans votre panier. Pour le modifier ou payer,',
      packagePricing: 'Tarifs des forfaits',
      packageQty: 'Nombre de forfaits',
      paymentCount: 'ou {count} paiements de',
      chooseYourQuantity: 'Choisissez votre quantité',
      readyToCall: '<span>Prêt à réserver ? Appelez le {phoneLink}</span> ou remplissez le formulaire pour des options de tarification et de forfait.',
      roomQty: 'Nombre de chambres',
      roomType: 'Type de chambre',
      soldOut: 'Épuisé',
      subtotal: 'Sous-total :',
      takenInCart: '(Ajouté au panier)',
      yourTicket: 'Votre billet',
      viewCart: 'voir votre panier»',
    }
  },
  it() {
    return {
      accommodation: 'Sistemazione',
      add: 'AGGIUNGI',
      addToCart: 'AGGIUNGI AL CARRELLO',
      addToBasket: 'Aggiungi Al Carrello',
      chooseYourPackage: 'Scegli il pacchetto e la quantità',
      continue: 'Continua',
      discount: 'Sconto',
      dueDateTextTwo: 'I pagamenti verranno suddivisi in due importi uguali che dovranno essere pagati alla firma e in data {date}',
      dueDateTextMultiple: 'I pagamenti verranno suddivisi in due importi uguali che dovranno essere pagati alla firma e in data {datesList}',
      error: 'Si è verificato un errore durante l\'invio dei dati',
      noAccommodations: 'Nessuna sistemazione',
      numberOfPeople: 'Numero di persone',
      package: 'Pacchetto',
      packageInCart: '{yourPackage} è nel carrello. Per modificare l\'ordine o per andare alla cassa,',
      packagePricing: 'Prezzo del pacchetto',
      packageQty: 'N. di pacchetti',
      paymentCount: 'oppure {count} pagamenti da',
      chooseYourQuantity: 'Scegli la quantità',
      readyToCall: '<span>Pronto a prenotare? Chiama subito al numero {phoneLink}</span> oppure compila il modulo se desideri conoscere i prezzi e le opzioni dei pacchetti.',
      roomQty: 'N. di camere',
      roomType: 'Tipo di stanza',
      soldOut: 'Esaurito',
      subtotal: 'Totale parziale:',
      takenInCart: '(nel carrello)',
      yourTicket: 'Biglietto',
      viewCart: 'visualizza carrello»',
    }
  },
  ja() {
    return {
      accommodation: '宿泊施設',
      add: 'ADD',
      addToCart: 'カートに追加',
      addToBasket: 'カートに追加',
      chooseYourPackage: 'パッケージと数量をご選択ください',
      continue: '続行',
      discount: 'ディスカウント',
      dueDateTextTwo: '支払いは、署名時と {date} の均等な分割になります',
      dueDateTextMultiple: '{datesList}の署名時に均等な分割のお支払いになります',
      error: '送信にエラーがありました。',
      noAccommodations: '宿泊なし',
      numberOfPeople: '# の人々',
      package: 'パッケージ',
      packageInCart: '{yourPackage}がカートにあります、編集または清算するには、',
      packagePricing: 'パッケージ価格',
      packageQty: 'パッケージ数量',
      paymentCount: 'または  {count}  回支払いで',
      chooseYourQuantity: '数量を選択してください',
      readyToCall: '<span>予約するには、 {phoneLink} へ電話するか</span> 、価格とパッケージオプションのフォームにご記入ください。',
      roomQty: '部屋数',
      roomType: 'ルームタイプ',
      soldOut: '完売',
      subtotal: '小計',
      takenInCart: '(カートに入れました)',
      yourTicket: 'お客様のチケット',
      viewCart: 'カートを見る»',
    }
  },
  pl() {
    return {
      accommodation: 'noclegi',
      add: 'ADD',
      addToCart: 'DODAJ DO KOSZYKA',
      addToBasket: 'Add To Basket',
      chooseYourPackage: 'Choose Your Package & Quantity',
      continue: 'kontynuuj',
      discount: 'rabat',
      dueDateTextTwo: 'płatności zostaną podzielone na równe raty, należne w momencie zamówienia i {date}',
      dueDateTextMultiple: 'płatności zostaną podzielone na równe raty, należne w momencie zamówienia w poniższych terminach: {datesList}',
      error: 'wystąpił błąd w Twoim zgłoszeniu',
      noAccommodations: 'bez noclegów',
      numberOfPeople: 'liczba osób',
      package: 'pakiet',
      packageInCart: '{yourPackage} is in your cart. To edit or checkout,',
      packagePricing: 'cena pakietu',
      packageQty: 'liczba pakietów',
      paymentCount: 'or {count} payments of',
      chooseYourQuantity: 'Choose Your Quantity',
      readyToCall: '<span>Ready to Book? Call {phoneLink}</span> or complete the form for pricing and package options.',
      roomQty: 'liczba pokoi',
      roomType: 'rodzaj pokoju',
      soldOut: 'sprzedane',
      subtotal: 'suma:',
      takenInCart: '(Taken in Cart)',
      yourTicket: 'Your Ticket',
      viewCart: 'view your cart»',
    }
  },
  po() {
    return {
      accommodation: 'Accommodation',
      add: 'ADD',
      addToCart: 'ADD TO CART',
      addToBasket: 'Add To Basket',
      chooseYourPackage: 'Choose Your Package & Quantity',
      continue: 'Continue',
      discount: 'Discount',
      dueDateTextTwo: 'Payments will be split equally and due at signing & on {date}',
      dueDateTextMultiple: 'Payments will be split equally and due at signing, on {datesList}',
      error: 'There was an error with your submission',
      noAccommodations: 'No Accommodations',
      numberOfPeople: '# of People',
      package: 'Package',
      packageInCart: '{yourPackage} is in your cart. To edit or checkout,',
      packagePricing: 'Package Pricing',
      packageQty: 'Package Qty',
      paymentCount: 'or {count} payments of',
      chooseYourQuantity: 'Choose Your Quantity',
      readyToCall: '<span>Ready to Book? Call {phoneLink}</span> or complete the form for pricing and package options.',
      roomQty: 'Room Qty',
      roomType: 'Room Type',
      soldOut: 'Sold Out',
      subtotal: 'Subtotal:',
      takenInCart: '(Taken in Cart)',
      yourTicket: 'Your Ticket',
      viewCart: 'view your cart»',
    }
  },
  zhCN() {
    return {
      accommodation: '住宿',
      add: '添加',
      addToCart: '添加至购物车',
      addToBasket: '加入购物车',
      chooseYourPackage: '选择您的套票及数量',
      continue: '继续',
      discount: '折扣',
      dueDateTextTwo: '款项将平分，并在签署日及{date}到期',
      dueDateTextMultiple: '付款将按期支付，并在签署日{datesList}全部支付完毕',
      error: '提交时出错',
      noAccommodations: '无住宿',
      numberOfPeople: '人数',
      package: '套票',
      packageInCart: '{yourPackage}已在您的购物车中。如需编辑或结算，',
      packagePricing: '套票价格',
      packageQty: '套票数量',
      paymentCount: '或{count}次各付',
      chooseYourQuantity: '选择数量',
      readyToCall: '<span>请致电{phoneLink}</span>或填写表格以选择价格及套票类型。',
      roomQty: '房间数量',
      roomType: '房间类型',
      soldOut: '售罄',
      subtotal: '小计：',
      takenInCart: '（加入购物车）',
      yourTicket: '您的门票',
      viewCart: '查看您的购物车»',
    }
  },
}
