export default {

  en() {
    return {
      continue_shopping: 'Continue Shopping',
      event: 'Event',
      no_orders: 'You currently have not completed any orders',
      order_date: 'Order Date',
      order_number: 'Order Number',
      show: 'Show',
      view: 'View'
    }
  },

  ar() {
    return {
      continue_shopping: 'واصل التسوّق',
      event: 'الحدث/الفعاليّة',
      no_orders: 'أنت حاليّا لم تُنهِ عمليّة تمرير أيّة طلبات',
      order_date: 'تاريخ تمرير الطلب',
      order_number: 'رقم الطلب',
      show: 'العرض',
      view: 'طريقة العرض',
    }
  },
  de() {
    return {
      continue_shopping: 'Weiter einkaufen',
      event: 'Veranstaltung',
      no_orders: 'Sie haben derzeit keine abgeschlossenen Bestellungen',
      order_date: 'Bestelldatum',
      order_number: 'Bestellnummer',
      show: 'Anzeigen',
      view: 'Anzeigen',
    }
  },
  enGB() {
    return {
      continue_shopping: 'Continue Shopping',
      event: 'Event',
      no_orders: 'You currently have not completed any orders',
      order_date: 'Order Date',
      order_number: 'Order Number',
      show: 'Show',
      view: 'View',
    }
  },
  esMX() {
    return {
      continue_shopping: 'Continuar Comprando',
      event: 'Evento',
      no_orders: 'Actualmente no cuenta con órdenes finalizadas',
      order_date: 'Fecha de Orden',
      order_number: 'Número de Orden',
      show: 'Mostrar',
      view: 'Ver',
    }
  },
  es() {
    return {
      continue_shopping: 'Continuar Comprando',
      event: 'Evento',
      no_orders: 'Actualmente no has completado ningún pedido',
      order_date: 'Fecha del Pedido',
      order_number: 'Número de Pedido',
      show: 'Mostrar',
      view: 'Ver',
    }
  },
  fr() {
    return {
      continue_shopping: 'Continuer vos achats',
      event: 'Événement',
      no_orders: 'Vous n\'avez actuellement effectué aucune commande.',
      order_date: 'Date de la commande',
      order_number: 'Numéro de commande',
      show: 'Montrer',
      view: 'Voir',
    }
  },
  it() {
    return {
      continue_shopping: 'Continua a fare acquisti',
      event: 'Evento',
      no_orders: 'Al momento non hai effettuato nessun ordine',
      order_date: 'Data dell\'ordine',
      order_number: 'Numero dell\'ordine',
      show: 'Mostra',
      view: 'Visualizza',
    }
  },
  ja() {
    return {
      continue_shopping: 'ショッピングを続ける',
      event: 'イベント',
      no_orders: '確定されたご注文はまだ何もありません',
      order_date: 'ご注文日',
      order_number: 'ご注文番号',
      show: '表示',
      view: '景色',
    }
  },
  pl() {
    return {
      continue_shopping: 'kontynuuj zakupy',
      event: 'impreza',
      no_orders: 'brak zamówień',
      order_date: 'data zamówienia',
      order_number: 'numer zamówienia',
      show: 'pokaż',
      view: 'zobacz',
    }
  },
  po() {
    return {
      continue_shopping: 'Continue Shopping',
      event: 'Event',
      no_orders: 'You currently have not completed any orders',
      order_date: 'Order Date',
      order_number: 'Order Number',
      show: 'Show',
      view: 'View',
    }
  },
  zhCN() {
    return {
      continue_shopping: '继续购物',
      event: '活动',
      no_orders: '您目前没有任何已完成订单',
      order_date: '订单日期',
      order_number: '订单号',
      show: '显示',
      view: '查看',
    }
  },
}
