export default {

  en() {
    return {
      addHotel: 'Add a hotel to your package',
      applyCredit: 'Apply Existing Credit',
      applyDiscount: 'Apply',
      cartEmpty: 'Your cart is empty.',
      cartEmptyGpe: 'Your basket is currently empty',
      continueShopping: 'Continue Shopping',
      credit:'Credit',
      creditNotApplicable: 'Credits are not applicable on the selected event. Please contact your sales rep if you have questions.',
      currency: 'Currency',
      deposit: 'Deposit',
      enhanceYourExperience: 'Enhance Your Experience',
      eventTransportation: 'Event Transportation',
      eventTransportationText: 'Transportation cost included in your total below.',
      mustAddHotel: 'You must add a hotel before you can purchase this package',
      paymentCount: 'Payment',
      previouslyPaid: 'Previous Payments',
      processingFee: 'Processing Fee',
      processingFeeTax: 'Processing Fee/Tax',
      promoCode: 'Promo Code',
      reviewYourOrder: 'Review Your Order',
      roomcash: 'RoomCash',
      shippingFee: 'Service & Handling Fee',
      subtotal: 'Subtotal',
      tax: 'Tax',
      transportationText: 'Event Transportation',
      vatTax: 'VAT Tax',
      yourCart: 'Your Cart',
      yourItems: 'Your Items',
      yourTotal: 'Your Total',
    }
  },
  ar() {
    return {
      addHotel: 'أضف حجز الفندق إلى باقتك',
      applyCredit: 'طبّق الائتمان الحالي',
      applyDiscount: 'ّتمّ تطبيق العرض الترويجي',
      cartEmpty: 'سلّة التسوّق الخاصّة بك لا تزال فارغة',
      cartEmptyGpe: 'سلّتك فارغة الآن',
      continueShopping: 'واصل التسوّق',
      credit: 'الرصيد الدائن',
      creditNotApplicable: '.الاعتمادات لا تنطبق على الحدث المحدّد. يُرْجى الاتّصال بمندوب المبيعات الخاصّ بك إذا كانت لديك أسئلة',
      currency: 'العملة',
      deposit: 'الإيداع',
      enhanceYourExperience: 'Enhance Your Experience',
      eventTransportation: 'الترقيم البريديّ',
      eventTransportationText: '.تكلفة النقل مضمّنة في مبلغك الإجمالي، أدناه',
      mustAddHotel: 'يتعيّن عليك إضافة حجز فندق قبل أن تتمكّن من اقتناء هذه الباقة',
      paymentCount: 'عمليّة الدفع',
      previouslyPaid: 'الدفوعات السابقة',
      processingFee: 'كلفة معالجة المسألة',
      processingFeeTax: 'كلفة معالجة المسألة/الضريبة',
      promoCode: 'رمز ترويجيّ',
      reviewYourOrder: 'راجع طلبك',
      roomcash: 'RoomCash',
      shippingFee: 'رسوم الخدمة والمناولة',
      subtotal: 'المجموع الفرعيّ',
      tax: 'الضريبة',
      transportationText: 'الترقيم البريديّ',
      vatTax: 'ضريبة القيمة المضافة',
      yourCart: 'Your Cart',
      yourItems: 'واصل إلى مرحلة الدفع',
      yourTotal: 'مجموعك ',
    }
  },
  de() {
    return {
      addHotel: 'Fügen Sie ein Hotel zu Ihrem Paket hinzu',
      applyCredit: 'Bestehendes Guthaben anwenden',
      applyDiscount: 'Anwenden',
      cartEmpty: 'Ihr Warenkorb ist leer',
      cartEmptyGpe: 'Ihr Warenkorb ist derzeit leer',
      continueShopping: 'Weiter einkaufen',
      credit: 'Gutschrift',
      creditNotApplicable: 'Gutschriften sind auf das ausgewählte Ereignis nicht anwendbar. Bei Fragen wenden Sie sich bitte an Ihren Verkaufsberater.',
      currency: 'Währung',
      deposit: 'Anzahlung',
      enhanceYourExperience: 'Enhance Your Experience',
      eventTransportation: 'Veranstaltungstransfers',
      eventTransportationText: 'Die Transportkosten sind in Ihrem Gesamtbetrag eingeschlossen.',
      mustAddHotel: 'Sie müssen ein Hotel hinzufügen, bevor Sie dieses Paket kaufen können.',
      paymentCount: 'Zahlung',
      previouslyPaid: 'Frühere Zahlungen',
      processingFee: 'Bearbeitungsgebühr',
      processingFeeTax: 'Bearbeitungsgebühr',
      promoCode: 'Promo-Code',
      reviewYourOrder: 'Ihre Bestellung überprüfen',
      roomcash: 'RoomCash',
      shippingFee: 'Service- und Bearbeitungsgebühr',
      subtotal: 'Zwischentotal',
      tax: 'Steuer',
      transportationText: 'Veranstaltungstransfers',
      vatTax: 'MwSt.',
      yourCart: 'Your Cart',
      yourItems: 'Ihre Artikel',
      yourTotal: 'Ihr Gesamtbetrag',
    }
  },
  enGB() {
    return {
      addHotel: 'Add a hotel to your package',
      applyCredit: 'Apply Existing Credit',
      applyDiscount: 'Apply',
      cartEmpty: 'Your cart is empty.',
      cartEmptyGpe: 'Your basket is currently empty',
      continueShopping: 'Continue Shopping',
      credit: 'Credit',
      creditNotApplicable: 'Credits are not applicable on the selected event. Please contact your sales rep if you have questions.',
      currency: 'Currency',
      deposit: 'Deposit',
      enhanceYourExperience: 'Enhance Your Experience',
      eventTransportation: 'Event Transportation',
      eventTransportationText: 'Transportation cost included in your total below.',
      mustAddHotel: 'You must add a hotel before you can purchase this package',
      paymentCount: 'Payment',
      previouslyPaid: 'Previous Payments',
      processingFee: 'Processing Fee',
      processingFeeTax: 'Processing Fee/Tax',
      promoCode: 'Promo Code',
      reviewYourOrder: 'Review Your Order',
      roomcash: 'RoomCash',
      shippingFee: 'Service & Handling Fee',
      subtotal: 'Subtotal',
      tax: 'Tax',
      transportationText: 'Event Transportation',
      vatTax: 'VAT Tax',
      yourCart: 'Your Cart',
      yourItems: 'Your Items',
      yourTotal: 'Your Total',
    }
  },
  esMX() {
    return {
      addHotel: 'Añada un hotel a su paquete',
      applyCredit: 'Aplicar Crédito Existente',
      applyDiscount: 'Aplicar',
      cartEmpty: 'Su carrito está vacío',
      cartEmptyGpe: 'Su carrito está vacío',
      continueShopping: 'Continuar Comprando',
      credit: 'Crédito',
      creditNotApplicable: 'Los créditos no son aplicables para el evento seleccionado. Por favor contacte a su representante de ventas si tiene preguntas.',
      currency: 'Moneda',
      deposit: 'Depósito',
      enhanceYourExperience: 'Enhance Your Experience',
      eventTransportation: 'Transporte del evento',
      eventTransportationText: 'Costos de transporte incluidos en tu total a continuación.',
      mustAddHotel: 'Debe añadir un hotel antes de que pueda adquirir este paquete',
      paymentCount: 'Pago',
      previouslyPaid: 'Pagos Previos',
      processingFee: 'Tarifa de Procesamiento',
      processingFeeTax: 'Cuota de Procesamiento / impuesto',
      promoCode: 'Código Promocional',
      reviewYourOrder: 'revise su orden',
      roomcash: 'RoomCash',
      shippingFee: 'Tarifa de servicio y gestión',
      subtotal: 'Subtotal',
      tax: 'Impuestos',
      transportationText: 'Transporte del evento',
      vatTax: 'VAT Tax',
      yourCart: 'Your Cart',
      yourItems: 'Sus Artículos',
      yourTotal: 'Su Total',
    }
  },
  es() {
    return {
      addHotel: 'Añade un hotel a tu paquete',
      applyCredit: 'Aplicar Crédito Existente',
      applyDiscount: 'Aplicar',
      cartEmpty: 'Tu carrito está vacío.',
      cartEmptyGpe: 'Tu carrito está actualmente vacío',
      continueShopping: 'Continuar Comprando',
      credit: 'Crédito',
      creditNotApplicable: 'Los créditos no son aplicables en el evento seleccionado. Por favor, contacta con tu representante de ventas si tienes dudas.',
      currency: 'Moneda',
      deposit: 'Depósito',
      enhanceYourExperience: 'Enhance Your Experience',
      eventTransportation: 'Transporte del Evento',
      eventTransportationText: 'El coste del transporte está incluido en el total.',
      mustAddHotel: 'Debes añadir un hotel antes de comprar este paquete',
      paymentCount: 'Pago',
      previouslyPaid: 'Pagos Anteriores',
      processingFee: 'Tasa de Tramitación',
      processingFeeTax: 'Impuesto/Tasa de Tramitación',
      promoCode: 'Código Promocional',
      reviewYourOrder: 'revisa tu pedido',
      roomcash: 'RoomCash',
      shippingFee: 'Tasa de Servicio y Gestión',
      subtotal: 'Subtotal',
      tax: 'Impuesto',
      transportationText: 'Transporte del Evento',
      vatTax: 'Impuesto del IVA',
      yourCart: 'Your Cart',
      yourItems: 'Tus Artículos',
      yourTotal: 'Tu Total',
    }
  },
  fr() {
    return {
      addHotel: 'Ajoutez un hôtel à votre forfait.',
      applyCredit: 'Appliquer un avoir existant',
      applyDiscount: 'Appliquer',
      cartEmpty: 'Votre panier est vide.',
      cartEmptyGpe: 'Votre panier est actuellement vide',
      continueShopping: 'Continuer vos achats',
      credit: 'Crédit',
      creditNotApplicable: 'Les crédits ne sont pas applicables pour l\'événement sélectionné. Veuillez contacter votre représentant commercial si vous avez des questions.',
      currency: 'Devise',
      deposit: 'Acompte',
      enhanceYourExperience: 'Enhance Your Experience',
      eventTransportation: 'Transport pour l\'événement',
      eventTransportationText: 'Coût du transport inclus dans votre total ci-dessous.',
      mustAddHotel: ' Pour pouvoir réserver ce forfait, vous devez préalablement ajouter un hôtel',
      paymentCount: 'Paiement',
      previouslyPaid: 'Paiements précédents',
      processingFee: 'Frais de traitement',
      processingFeeTax: 'Frais/taxe de traitement',
      promoCode: 'Code Promo',
      reviewYourOrder: 'vérifier votre commande',
      roomcash: 'RoomCash',
      shippingFee: 'Frais de service et de traitement',
      subtotal: 'Sous-total',
      tax: 'Taxe',
      transportationText: 'Transport pour l\'événement',
      vatTax: 'TVA',
      yourCart: 'Your Cart',
      yourItems: 'Vos articles',
      yourTotal: 'Votre total',
    }
  },
  it() {
    return {
      addHotel: 'Aggiungi un albergo al tuo pacchetto',
      applyCredit: 'Utilizza il credito esistente',
      applyDiscount: 'Applica',
      cartEmpty: 'Il tuo carrello è vuoto.',
      cartEmptyGpe: 'Il carrello è vuoto',
      continueShopping: 'Continua a fare acquisti',
      credit: 'Credito',
      creditNotApplicable: 'I crediti non possono essere utilizzati con l\'evento selezionato. Ti invitiamo a contattare un addetto alle vendite in caso di ulteriori domande.',
      currency: 'Valuta',
      deposit: 'PRENOTATI IN ANTEPRIMA',
      enhanceYourExperience: 'Enhance Your Experience',
      eventTransportation: 'Trasferimenti agli eventi',
      eventTransportationText: 'Il costo dei trasferimenti è incluso nel totale riportato qui di seguito.',
      mustAddHotel: 'È necessario aggiungere un albergo prima di poter acquistare questo pacchetto',
      paymentCount: 'Pagamento',
      previouslyPaid: 'Pagamenti precedenti',
      processingFee: 'Spese di commissione',
      processingFeeTax: 'Imposte',
      promoCode: 'Codice promozionale',
      reviewYourOrder: 'controlla il tuo ordine',
      roomcash: 'RoomCash',
      shippingFee: 'Commissione per servizio e gestione',
      subtotal: 'Totale parziale',
      tax: 'Imposte',
      transportationText: 'Trasferimenti agli eventi',
      vatTax: 'IVA',
      yourCart: 'Your Cart',
      yourItems: 'I tuoi articoli',
      yourTotal: 'Il tuo totale',
    }
  },
  ja() {
    return {
      addHotel: 'パッケージにホテルを追加する',
      applyCredit: '既存のクレジットを適用',
      applyDiscount: '適用する',
      cartEmpty: 'ショッピングカートには何も入っていません。',
      cartEmptyGpe: 'お客様のバスケットは現在空です',
      continueShopping: 'ショッピングを続ける',
      credit: 'クレジット',
      creditNotApplicable: '選択したイベントにクレジットは適用されません。ご不明な点は、営業担当にお問い合わせください。',
      currency: '通貨',
      deposit: 'デポジット',
      enhanceYourExperience: 'Enhance Your Experience',
      eventTransportation: 'イベントの交通手段',
      eventTransportationText: '以下の合計には交通費も含まれます。',
      mustAddHotel: 'このパッケージを購入する前にホテルを追加する必要があります',
      paymentCount: '支払い',
      previouslyPaid: '過去の支払い',
      processingFee: '手数料',
      processingFeeTax: '取引手数料／税',
      promoCode: 'プロモーションコード',
      reviewYourOrder: '注文内容を確認する',
      roomcash: 'RoomCash',
      shippingFee: 'サービス＆手数料',
      subtotal: '小計',
      tax: '税',
      transportationText: 'イベントの交通手段',
      vatTax: '付加価値税',
      yourCart: 'Your Cart',
      yourItems: 'お客様のアイテム',
      yourTotal: '合計金額',
    }
  },
  pl() {
    return {
      addHotel: 'Add a hotel to your package',
      applyCredit: 'Apply Existing Credit',
      applyDiscount: 'zastosuj',
      cartEmpty: 'Twój koszyk jest pusty.',
      cartEmptyGpe: 'Your basket is currently empty',
      continueShopping: 'kontynuuj zakupy',
      credit: 'Credit',
      creditNotApplicable: 'Credits are not applicable on the selected event. Please contact your sales rep if you have questions.',
      currency: 'waluta',
      deposit: 'Deposit',
      enhanceYourExperience: 'Enhance Your Experience',
      eventTransportation: 'Event Transportation',
      eventTransportationText: 'Transportation cost included in your total below.',
      mustAddHotel: 'You must add a hotel before you can purchase this package',
      paymentCount: 'płatność',
      previouslyPaid: 'Previous Payments',
      processingFee: 'opłata manipulacyjna',
      processingFeeTax: 'opłata manipulacyjna/podatek',
      promoCode: 'kod promocyjny',
      reviewYourOrder: 'sprawdź swoje zamówienie',
      roomcash: 'RoomCash',
      shippingFee: 'Service & Handling Fee',
      subtotal: 'suma',
      tax: 'podatek',
      transportationText: 'Event Transportation',
      vatTax: 'podatek VAT',
      yourCart: 'Your Cart',
      yourItems: 'zamówione pozycje',
      yourTotal: 'suma ogółem',
    }
  },
  po() {
    return {
      addHotel: 'Add a hotel to your package',
      applyCredit: 'Apply Existing Credit',
      applyDiscount: 'Apply',
      cartEmpty: 'Your cart is empty.',
      cartEmptyGpe: 'Your basket is currently empty',
      continueShopping: 'Continue Shopping',
      credit: 'Credit',
      creditNotApplicable: 'Credits are not applicable on the selected event. Please contact your sales rep if you have questions.',
      currency: 'Currency',
      deposit: 'Deposit',
      enhanceYourExperience: 'Enhance Your Experience',
      eventTransportation: 'Event Transportation',
      eventTransportationText: 'Transportation cost included in your total below.',
      mustAddHotel: 'You must add a hotel before you can purchase this package',
      paymentCount: 'Payment',
      previouslyPaid: 'Previous Payments',
      processingFee: 'Processing Fee',
      processingFeeTax: 'Processing Fee/Tax',
      promoCode: 'Promo Code',
      reviewYourOrder: 'review your order',
      roomcash: 'RoomCash',
      shippingFee: 'Service & Handling Fee',
      subtotal: 'Subtotal',
      tax: 'Tax',
      transportationText: 'Event Transportation',
      vatTax: 'VAT Tax',
      yourCart: 'Your Cart',
      yourItems: 'Your Items',
      yourTotal: 'Your Total',
    }
  },
  zhCN() {
    return {
      addHotel: '将酒店添加到您的套票中',
      applyCredit: '可抵扣现有积分',
      applyDiscount: '使用',
      cartEmpty: '您的购物车是空的。',
      cartEmptyGpe: '您的购物篮目前是空的',
      continueShopping: '继续购物',
      credit: '积分',
      creditNotApplicable: '积分不适用于所选赛事。如果您有任何疑问，请与您的销售代表联系。',
      currency: '货币',
      deposit: '订金',
      enhanceYourExperience: 'Enhance Your Experience',
      eventTransportation: '活动交通',
      eventTransportationText: '交通费已含在如下总费用里。',
      mustAddHotel: '购买此套票前必须先添加酒店',
      paymentCount: '支付',
      previouslyPaid: '前期支付',
      processingFee: '处理费',
      processingFeeTax: '手续费/税',
      promoCode: '促销码',
      reviewYourOrder: '查看您的订单',
      roomcash: 'RoomCash',
      shippingFee: '服务及手续费',
      subtotal: '小计',
      tax: '税费',
      transportationText: '活动交通',
      vatTax: '增值税',
      yourCart: 'Your Cart',
      yourItems: '您的物品',
      yourTotal: '总额',
    }
  },
}
